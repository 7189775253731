.logo {
  line-height: 1;
  @include sp-screen {
    margin-bottom: 16px;
  }
  &__sub {
    display: block;
    @include rem-font-size(17);
    margin-bottom: 1.2rem;
    letter-spacing: 0.04em;
    @include sp-screen {
      margin-bottom: 0.6rem;
      @include rem-font-size(12);
    }
  }
  &__name {
    display: block;
    @include rem-font-size(44);
    font-weight: bold;
    font-family: 'Kosugi Maru', sans-serif;
    color: $mainColor;
    @include sp-screen {
      @include rem-font-size(22);
    }
  }
  &__img {
    display: block;
    img {
      width: 400px;
    }
    @include sp-screen {
      @include rem-font-size(22);
      img {
        width: 100%;
      }
    }
  }
}

.header {
  position: relative;
  @include sp-screen {
    .sp-menu-btn {
      position: absolute;
      display: block;
      width:30px;
      background-color: $mainColor;
      padding: 12px 8px;
      top:0;
      right:0;
      line-height: 0;
    }
  }
  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1024px;
    margin: 0 auto;
    padding: 16px 0;
    @include sp-screen {
      flex-direction: column;
      margin: 0 5%;
      padding: 30px 0 16px 0;
    }
  }
  .catch {
    @include pc-screen {
      text-align: right;
      @include rem-font-size(14);
      letter-spacing: 0.16em;
    }
    @include sp-screen {
      margin-bottom: 8px;
      @include rem-font-size(12);
    }
  }
  .address {
    font-size: 0;
    @include pc-screen {
      text-align: right;
    }
    @include sp-screen {
      text-align: center;
    }
    dt,
    dd {
      display: inline-block;
      @include rem-font-size(14);
      letter-spacing: 0.16em;
      @include sp-screen {
        @include rem-font-size(12);
      }
    }
    dt {
      margin-right: .5em;
    }
  }
  .tel {
    position: relative;
    padding-left: 32px;
    display: flex;
    justify-content: flex-end;
    line-height: 1.4;
    @include sp-screen {
      display: block;
      padding-left: 35px;
      justify-content: space-between;
    }
    @include pc-screen {
      text-align: right;
    }
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      display: block;
      width: 24px;
      height: 26px;
      background: url(../img/common/icon_tel.png) no-repeat;
      background-size: contain;
      @include sp-screen {
        width: 30px;
        height: 30px
      }
    }
    dl {
      margin-right: 1em;
      &:last-child {
        margin-right: 0;
      }
      @include sp-screen {
        display: block;
        width: 100%;
      }
    }
    dt,
    dd {
      display: inline-block;
      @include rem-font-size(22);
      @include sp-screen {
        @include rem-font-size(20);
      }
    }
    dt {
      @include sp-screen {
        @include rem-font-size(18);
      }
    }
  }
}

.navi-area {
  border-top: solid 1px #c5c5c5;
  @include sp-screen {
    position: absolute;
    width:100%;
    top:114px;
    left:0;
    background-color: #fff;
    display: none;
    border-bottom: solid 1px #c5c5c5;
  }
}
.navi {
  max-width: 1024px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  @include rem-font-size(18);
  line-height: 1.4;
  letter-spacing: 0.08em;
  @include sp-screen {
    flex-wrap: wrap;
    @include rem-font-size(13);
  }
  li {
    width: 20%;
    height: 80px;
    border-left: solid 1px #c5c5c5;
    &.current {
      background: $mainColor;
      a {
        color: $white;
      }
    }
    @include sp-screen {
      width: 100%;
      height: 40px;
      box-sizing: border-box;
      border-bottom: solid 1px #c5c5c5;
      &:nth-child(odd) {
        border-left: none;
      }
    }
    &:last-child {
      border-right: solid 1px #c5c5c5;
      @include sp-screen {
        width: 100%;
        border: none;
      }
    }
  }
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    height: 80px;
    @include sp-screen {
      height: 40px;
    }
    @include pc-screen {
      &:hover {
        background: $mainColor;
        color: $white;
      }
    }
  }
}

.kv {
  padding-top: 27%;
}

.bottom-link {
  margin-bottom: 50px;
  padding: 30px 0;
  background: rgba($mainColor, 0.1);
  @include sp-screen {
    margin-bottom: 25px;
  }
  &__inner {
    max-width: 880px;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    @include sp-screen {
      margin: 0 5%;
      justify-content: space-between;
    }
  }
  li {
    @include sp-screen {
      width: 32%;
    }
  }
  &__item {
    background: $white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    letter-spacing: 0.08em;
    @include pc-screen {
      width: 250px;
      height: 230px;
      @include rem-font-size(22);
    }
    @include sp-screen {
      width: 100%;
      height: 100%;
      padding: 10px 5px;
      box-sizing: border-box;
      @include rem-font-size(12);
    }
    img {
      width: 140px;
      margin-bottom: 30px;
      @include sp-screen {
        width: 80px;
        margin-bottom: 15px;
      }
    }
  }
}

.page-top {
  position: relative;
  display: block;
  padding: 40px 0 20px;
  width: 100%;
  background: #f0f0f0;
  text-align: center;
  @include rem-font-size(18);
  letter-spacing: 0.08em;
  @include sp-screen {
    padding: 30px 0 5px;
    @include rem-font-size(14);
  }
  &::before {
    position: absolute;
    top: 20px;
    left: 0;
    right: 0;
    margin: auto;
    content: "";
    display: block;
    width: 28px;
    height: 16px;
    background: url(../img/common/icon_up.png) no-repeat center;
    background-size: contain;
    @include sp-screen {
      top: 10px;
    }
  }
}

.footer {
  &__inner {
    max-width: 1024px;
    margin: 0 auto;
    padding: 40px 0 50px;
    @include sp-screen {
      margin: 0 5%;
    }
  }
  &__card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 1px #c3c3c3;
    padding-bottom: 30px;
    margin-bottom: 20px;
    @include sp-screen {
      padding-bottom: 15px;
      margin-bottom: 15px;
      flex-direction: column;
    }
    .logo {
      img {
        width: 400px;
        @include sp-screen {
          width: 100%;
        }
      }
    }
  }
  .address {
    text-align: right;
    font-size: 0;
    @include sp-screen {
      text-align: left;
    }
    dt,
    dd {
      display: inline-block;
      @include rem-font-size(14);
      letter-spacing: 0.16em;
      @include sp-screen {
        @include rem-font-size(12);
      }
    }
    dt {
      margin-right: .5em;
    }
  }
  .tel {
    position: relative;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    line-height: 1.4;
    @include sp-screen {
      text-align: left;
      justify-content: flex-start;
    }
    dl {
      margin-right: 1em;
      letter-spacing: 0.16em;
      &:last-child {
        margin-right: 0;
      }
    }
    dt {
      margin-right: 0.5em;
    }
    dt,
    dd {
      display: inline-block;
      @include rem-font-size(14);
      @include sp-screen {
        @include rem-font-size(12);
      }
    }
  }
  &__link-area {
    margin-bottom: 40px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    @include sp-screen {
      margin-bottom: 20px;
      justify-content: space-around;
    }
  }
  &__navi {
    display: flex;
    flex-wrap: wrap;
    width: 60%;
    li {
      box-sizing: border-box;
      min-width: 180px;
      padding-left: 10px;
      margin-bottom: 10px;
      @include rem-font-size(14);
      color: $mainColor;
      @include sp-screen {
        padding-left: 0px;
        min-width: inherit;
        width: 100%;
      }
      a {
        color: $mainColor;
      }
    }
  }
  .contact {
    background: $mainColor;
    width: 100px;
    padding: 8px 0 0 8px;
    border: solid 2px $mainColor;
    box-shadow: 1px 2px 1px rgba($mainColor, 0.5);
    img {
      display: block;
      width: 100%;
    }
  }
  .copyright {
    text-align: center;
    @include rem-font-size(13);
    @include sp-screen {
      @include rem-font-size(11);
    }
  }
}
