@include sp-screen {}

.page-index {
  .kv {
    background: url(../img/top/key_visual.png) no-repeat;
    background-size: cover;
  }
  .content {
    counter-reset: number;
    &__ttl {
      margin-bottom: 50px;
      padding: 30px 0 15px;
      text-align: center;
      border-bottom: solid 1px #ccc;
      @include rem-font-size(25);
      font-weight: bold;
      letter-spacing: 0.12em;
      @include sp-screen {
        margin-bottom: 25px;
        padding: 15px 0 8px;
        @include rem-font-size(20);
      }
    }
  }
  .wrap {
    @include pc-screen {
      width: 880px;
      margin: 0 auto;
    }
    @include sp-screen {
      width: auto;
      margin: 0 5%;
    }
  }
  .lead {
    margin-bottom: 100px;
    font-weight: bold;
    @include sp-screen {
      margin-bottom: 50px;
    }
    &__ttl {
      margin-bottom: 10px;
      text-align: center;
      @include rem-font-size(22);
      letter-spacing: 0.12em;
      @include sp-screen {
        @include rem-font-size(18);
      }
    }
    &__text {
      word-break: break-all;
      @include pc-screen {
        @include rem-font-size(18);
        width: 64%;
        margin: 0 auto;
      }
      @include sp-screen {
        @include rem-font-size(14);
      }
    }
  }
  .tel-info {
    width: 740px;
    box-sizing: border-box;
    margin-right: auto;
    margin-left: auto;
    padding: 40px 70px;
    border-radius: 7px;
    line-height: 1.4;
    background: rgba($mainColor, 0.1);
    @include rem-font-size(50);
    @include sp-screen {
      width: auto;
      padding: 20px 5%;
      @include rem-font-size(30);
    }
    &__item {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding-bottom: 15px;
      margin-bottom: 15px;
      border-bottom: solid 1px $mainColor;
      &:last-child {
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: none;
      }
      .note {
        @include rem-font-size(17);
        @include sp-screen {
          @include rem-font-size(12);
        }
      }
    }
    .item--tel {
      &::before {
        content: "";
        display: block;
        width: 56px;
        height: 56px;
        margin-right: 30px;
        background: url(../img/visiting_care/icon_tel.png) no-repeat;
        background-size: contain;
        @include sp-screen {
          width: 28px;
          height: 28px;
          margin-right: 5%;
        }
      }
      a {
        display: block;
      }
    }
    .item--fax {
      &::before {
        content: "";
        display: block;
        width: 56px;
        height: 81px;
        margin-right: 30px;
        background: url(../img/visiting_care/icon_fax.png) no-repeat;
        background-size: contain;
        @include sp-screen {
          width: 28px;
          height: 40px;
          margin-right: 5%;
        }
      }
      a {
        display: block;
      }
    }
  }
  .block {
    margin-bottom: 120px;
    @include sp-screen {
      margin-bottom: 60px;
    }
    &__ttl {
      margin-bottom: 50px;
      @include rem-font-size(22);
      letter-spacing: 0.12em;
      text-align: center;
      font-weight: bold;
      @include sp-screen {
        margin-bottom: 25px;
        @include rem-font-size(18);
      }
      &--2 {
        margin-bottom: 30px;
        @include sp-screen {
          margin-bottom: 15px;
        }
      }
      &.num {
        &::before {
          display: block;
          counter-increment: number;
          content: counter(number, decimal-leading-zero);
          @include rem-font-size(45);
          font-family: lato, sans-serif;
          line-height: 1;
          color: $mainColor;
          @include sp-screen {
            @include rem-font-size(28);
          }
        }
      }
      span {
        position: relative;
        display: inline-block;
        padding: 0 120px;
        @include sp-screen {
          padding: 0 30px;
        }
        &::before,
        &::after {
          content: '';
          position: absolute;
          top: 50%;
          display: inline-block;
          width: 100px;
          height: 1px;
          background-color: $black;
          @include sp-screen {
            width: 20px;
          }
        }
        &::before {
          left:0;
        }
        &:after {
          right: 0;
        }
      }
    }
    &__list {
      display: flex;
      justify-content: space-between;
      @include sp-screen {
        flex-direction: column;
      }
      .list-wrap {
        width: 47%;
        @include sp-screen {
          width: auto;
          margin: 0 auto;
        }
      }
      .list {
        margin-bottom: 30px;
        @include sp-screen {
          margin-bottom: 15px;
        }
      }
      .case {
        display: flex;
        align-items: center;
        @include rem-font-size(27);
        @include sp-screen {
          @include rem-font-size(16);
        }
        &__img {
          width: 110px;
          margin-right: 16px;
          @include sp-screen {
            width: 60px;
          }
          img {
            display: block;
            width: 100%;
          }
        }
      }
      .ex {
        margin: 24px 0 60px;
        @include rem-font-size(24);
        @include sp-screen {
          margin: 12px 0;
          @include rem-font-size(14);
        }
        &::before {
          content: "例：";
          color: $mainColor;
        }
        li {
          display: flex;
          align-items: center;
          line-height: 2;
          &::before {
            content: "■";
            color: $mainColor;
            @include rem-font-size(12);
            margin-right: 1em;
          }
        }
      }
      .note {
        @include rem-font-size(18);
        color: $mainColor;
        text-align: center;
        @include sp-screen {
          margin-bottom: 10px;
          @include rem-font-size(12);
        }
      }
    }
    &__img {
      margin-bottom: 100px;
      @include sp-screen {
        margin-bottom: 50px;
      }
      &:last-child {
        margin-bottom: 0;
      }
      img {
        display: block;
        width: 100%;
      }
    }
    &__lead {
      margin-bottom: 15px;
      @include rem-font-size(18);
      text-align: center;
      @include sp-screen {
        text-align: left;
        @include rem-font-size(14);
      }
    }
    &__table {
      width: 100%;
      tr:first-child {
        th,td {
          border-bottom: none;
        }
      }
      th,td {
        padding: 10px 20px;
        border-right: solid 1px $white;
        border-bottom: solid 1px $white;
        text-align: center;
        line-height: 1.4;
        @include sp-screen {
          padding: 10px;
        }
      }
      th {
        @include rem-font-size(19);
        @include sp-screen {
          @include rem-font-size(14);
        }
        &.bg-light-main {
          color: $mainColor;
        }
      }
      td {
        @include rem-font-size(18);
        @include sp-screen {
          @include rem-font-size(12);
        }
      }
      .bg-main {
        background: $mainColor;
        color: $white;
      }
      .bg-light-main {
        background: rgba($mainColor, 0.1);
      }
      .bg-sub {
        background: $subColor;
        color: $white;
      }
      .bg-light-sub {
        background: rgba($subColor, 0.1);
      }
      .align-l {
        text-align: left;
      }
    }
  }
}
