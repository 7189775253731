@include sp-screen {}

.page-recruit {
  .kv {
    background: url(../img/recruit/key_visual.png) no-repeat;
    background-size: cover;
  }
  .content {
    counter-reset: number;
    &__ttl {
      padding: 45px 0 30px;
      text-align: center;
      @include rem-font-size(30);
      font-weight: normal;
      letter-spacing: 0.12em;
      @include sp-screen {
        padding: 15px 0;
        @include rem-font-size(20);
      }
    }
  }
  .wrap {
    @include pc-screen {
      width: 1024px;
      margin: 0 auto;
    }
    @include sp-screen {
      width: auto;
      margin: 0 5%;
    }
  }
  .recruit {
    margin-bottom: 120px;
    &__ttl {
      margin-bottom: 30px;
      @include rem-font-size(23);
      text-align: center;
      &__img {
        width: 60px;
        margin: 0 auto 10px;
        img {
          width: 100%;
          display: block;
        }
      }
    }
  }
  .recruit_position > p {
    margin-bottom: 30px;
    text-align: center;
    @include rem-font-size(14);
  }

  .recruit_position > ul > li {
    margin-bottom: 30px;
  }
  .recruit_position > ul > li > a {
    display: flex;
    justify-content: space-between;
    background-color: #eeeeee;
    padding: 10px 20px 10px 10px;
  }
  .recruit_position > ul > li > a:hover {
    opacity: 0.7;
    text-decoration: none;
  }
  .recruit_position > ul > li > a .recruit_position_list_img {
    width: 391px;
  }
  .recruit_position > ul > li > a .recruit_position_list_txt {
    width: 615px;
    padding: 10px 0;
  }
  .recruit_position > ul > li > a .recruit_position_list_txt h3 {
    margin-bottom: 15px;
    color: $mainColor;
    font-weight: 500;
    font-size: 20px;
  }
  .recruit_position > ul > li > a .recruit_position_list_txt p {
    color: #333;
    line-height: 1.5;
    font-size: 16px;
  }
  .recruit_position > ul > li > a .recruit_position_list_more {
    position: relative;
    margin-bottom: 15px;
    padding-right: 10px;
    color: $mainColor;
    text-align: right;
    font-size: 14px;
    &::after {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 2px;
      margin: auto;
      vertical-align: middle;
      content: "";
      width: 6px;
      height: 6px;
      border-top: 2px solid $mainColor;
      border-right: 2px solid $mainColor;
      transform: rotate(45deg);
      @include sp-screen {
        width: 5px;
        height: 5px;
      }
    }
  }
  .recruit_position > ul > li > a .recruit_position_list_tag li {
    display: inline-block;
    margin: 0 5px 5px 0;
    border-radius: 1em;
    background-color: $mainColor;
    padding: 0.5em 1em;
    color: #fff;
    line-height: 1;
    text-align: center;
    font-size: 16px;
  }

  @include sp-screen {
    .recruit_position {
      margin: 0 auto;
      width: 100%;
    }
    .recruit_position > p {
      margin-bottom: 10px;
    }
    .recruit_position > ul > li {
      margin-bottom: 15px;
    }
    .recruit_position > ul > li > a {
      display: block;
      padding: 10px;
    }
    .recruit_position > ul > li > a .recruit_position_list_img {
      display: none;
    }
    .recruit_position > ul > li > a .recruit_position_list_txt {
      width: 100%;
      padding: 0;
    }
    .recruit_position > ul > li > a .recruit_position_list_txt h3 {
      margin-bottom: 10px;
      font-size: 14px;
    }
    .recruit_position > ul > li > a .recruit_position_list_txt p {
      font-size: 12px;
    }
    .recruit_position > ul > li > a .recruit_position_list_more {
      margin-bottom: 10px;
      font-size: 10px;
    }
    .recruit_position > ul > li > a .recruit_position_list_tag li {
      font-size: 11px;
    }

  }
}
